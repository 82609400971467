import { useEffect, useState } from 'react'

import { OnboardingBanner } from '../../../components'
import { CertificateStyled } from './CertificateModal.styled'
import { Button, DragAndDrop, ErrorDisplay, FileInput, Input } from 'mmfintech-portal-commons'

import { checkFileUpload, isNotEmptyString, isValidFunction, tr } from 'mmfintech-commons'
import {
  isMerchantProspect,
  useEnotasAuthenticateMutation,
  useGetEnotasMerchantMetadataQuery,
  useGetMerchantQuery
} from 'mmfintech-backend-api'

import LockIcon from '../../../images/icons/input-lock.svg?react'

const certificateAcceptType = ['pfx', 'p12']
const certificateMaxFileSize = 1024 * 1024

type CertificateModalProps = {
  onClose: () => void
  onReject?: () => void
  onSuccess?: () => void
}

export const CertificateModal = ({ onClose, onReject, onSuccess }: CertificateModalProps) => {
  const [file, setFile] = useState(null)
  const [fileError, setFileError] = useState(null)
  const [password, setPassword] = useState<string>(null)

  const { data: merchant } = useGetMerchantQuery()

  const [authenticate, { isLoading: authenticateFetching, error: authenticateError, reset: authenticateReset }] =
    useEnotasAuthenticateMutation()

  const { data: metadata } = useGetEnotasMerchantMetadataQuery(null)

  const { certificateOptional } = metadata || {}

  const isDataValid = () => file != null && isNotEmptyString(password)

  const handleSelected = (list: any) => {
    setFileError(null)
    if (Array.isArray(list) && list.length) {
      setFile(list[0])
    }
  }

  const handleError = (errors: any) => setFileError(checkFileUpload(errors))

  const handleSubmit = async () => {
    try {
      await authenticate({
        file: file,
        password
      }).unwrap()

      onClose()
      isValidFunction(onSuccess) && onSuccess()
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    return () => {
      setFile(null)
      setFileError(null)
      setPassword(null)
      authenticateReset()
    }
  }, [])

  return (
    <CertificateStyled data-test='enotas-certificate-modal'>
      <div className='title'>{tr('FRONTEND.ENOTAS.CERTIFICATE.TITLE', 'Certificate')}</div>

      <ErrorDisplay error={[fileError, authenticateError]} />

      <DragAndDrop
        onDrop={handleSelected}
        onError={handleError}
        maxFileSize={certificateMaxFileSize}
        acceptType={certificateAcceptType}>
        <FileInput
          maxNumber={1}
          maxFileSize={certificateMaxFileSize}
          acceptType={certificateAcceptType}
          onChange={handleSelected}
          onError={handleError}>
          {({ onFileUpload, onRemoveAll }) => (
            <>
              <div
                className='upload-box'
                data-test='upload-box'
                onClick={() => {
                  onRemoveAll()
                  onFileUpload()
                }}>
                <span className='vertical-text'>
                  <span>{tr('FRONTEND.ENOTAS.CERTIFICATE.FILE_LABEL', 'Upload certificate')}</span>
                  <div className='file-types'>
                    {file ? file.name : tr('FRONTEND.ENOTAS.CERTIFICATE.FILE_PLACEHOLDER', 'file extension - .pfx')}
                  </div>
                </span>
              </div>
            </>
          )}
        </FileInput>
      </DragAndDrop>

      <div className='mb-2' />

      <Input
        type='password'
        name='password'
        icon={<LockIcon />}
        parentClassName='mt-4'
        label={tr('FRONTEND.ENOTAS.CERTIFICATE.PASSWORD_LABEL', 'Certificate password')}
        onChange={(_name: string, value: string) => setPassword(value)}
        value={password || ''}
        required={true}
        data-test='password'
        disabled={isMerchantProspect(merchant)}
        hideShowPassword
      />

      <OnboardingBanner />

      <div className='buttons'>
        <Button
          type='button'
          color='round-primary'
          loading={authenticateFetching}
          disabled={authenticateFetching || !isDataValid() || isMerchantProspect(merchant)}
          text={tr('FRONTEND.ENOTAS.CERTIFICATE.SUBMIT_BUTTON', 'Submit')}
          onClick={handleSubmit}
          data-test='button-upload'
        />

        {certificateOptional && (
          <Button
            type='button'
            color='round-secondary'
            onClick={onReject}
            text={tr('FRONTEND.ENOTAS.CERTIFICATE.REJECTED_LABEL', 'I do not have a certificate')}
            disabled={isMerchantProspect(merchant)}
            data-test='button-reject'
          />
        )}
      </div>
    </CertificateStyled>
  )
}
