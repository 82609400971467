import React from 'react'
import { Link } from 'react-router-dom'

import cn from 'classnames'

import { tr, useDropDownMenu } from 'mmfintech-commons'
import { getMerchantAccountTypeName, useGetMerchantQuery, useLogoutQry } from 'mmfintech-backend-api'

import { CustomUserMenuIcon } from './CustomUserMenuIcon'
import { DropDownMenuContainer, DropDownMenuIcon, DropDownMenuWrapper } from './UserDropDownMenu.styled'

type UserDropDownMenuProps = {
  customUserMenuIcon?: boolean
  customMenuItem?: boolean
  customLogoutButton?: boolean
  customUserMenuHeader?: boolean
  UserMenuItems?: any
}

export const UserDropDownMenu = (props: UserDropDownMenuProps) => {
  const { customUserMenuIcon, customMenuItem, customLogoutButton, customUserMenuHeader, UserMenuItems } = props

  const { data: merchant } = useGetMerchantQuery()

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const { logout } = useLogoutQry(true)

  const handleLogoutClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    hideDropDown()
    void logout()
  }

  const LogoutMenuItem = () => (
    <Link to='#' className='menu-item logout' onClick={handleLogoutClick}>
      {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
    </Link>
  )

  return (
    <DropDownMenuWrapper>
      {customUserMenuIcon ? (
        <CustomUserMenuIcon merchantName={merchant?.name} onClick={toggleDropDown} />
      ) : (
        <DropDownMenuIcon onClick={toggleDropDown}>
          <span className={cn('menu-icon', 'user-menu-icon', { visible })} />
        </DropDownMenuIcon>
      )}

      {visible && (
        <DropDownMenuContainer className={cn({ custom: customUserMenuIcon })}>
          <div className='triangle' />
          {customUserMenuHeader && (
            <div className='account-info'>
              <span>{merchant?.name}</span>
              <span>{getMerchantAccountTypeName(merchant)}</span>
            </div>
          )}

          <UserMenuItems onClick={hideDropDown} className={cn('menu-item', { selector: customMenuItem })} />
          {!customLogoutButton ? (
            <div className='menu-footer'>
              <LogoutMenuItem />
            </div>
          ) : (
            <LogoutMenuItem />
          )}
        </DropDownMenuContainer>
      )}
    </DropDownMenuWrapper>
  )
}
