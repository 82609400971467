import { Banner } from './Banner'

import { tr } from 'mmfintech-commons'
import { useOnboarding } from '../../hooks'

import { MerchantAccountTypeEnum, OnboardingProviderEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'
import { useGetMerchantQuery } from 'mmfintech-backend-api'

type OnboardingBannerProps = {
  button?: boolean
  className?: string
}

export const OnboardingBanner = ({ button, className }: OnboardingBannerProps) => {
  const { data: merchant, isFetching } = useGetMerchantQuery()
  const { accountType, onboardingProvider, onboardingStatus } = merchant || {}

  const { startOrContinueOnboarding } = useOnboarding()

  if (!isFetching && onboardingProvider === OnboardingProviderEnum.SUMSUB) {
    if (onboardingStatus === OnboardingStatusEnum.APPROVED_EXPIRING_DOCUMENT) {
      return (
        <Banner
          button={button}
          className={className}
          header={tr('FRONTEND.ONBOARDING.EXPIRING_DOCUMENT.HEADING', 'Expiring document')}
          content={tr(
            'FRONTEND.ONBOARDING.EXPIRING_DOCUMENT.MESSAGE',
            'Your document is expiring, upload newer one to avoid limitation of services'
          )}
          buttonCaption={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
          handleClick={startOrContinueOnboarding}
        />
      )
    }

    if (accountType === MerchantAccountTypeEnum.PROSPECT) {
      if (
        onboardingStatus === OnboardingStatusEnum.NOT_STARTED ||
        onboardingStatus === OnboardingStatusEnum.IN_PROGRESS ||
        onboardingStatus === OnboardingStatusEnum.REJECTED_RETRY
      ) {
        return (
          <Banner
            button={button}
            className={className}
            header={tr('FRONTEND.ONBOARDING.RESUME_ALERT_TITLE', 'Complete your account')}
            content={tr(
              'FRONTEND.ONBOARDING.RESUME_ALERT_TEXT',
              'Verify to get access to all features and increased payment limits.'
            )}
            buttonCaption={
              onboardingStatus === OnboardingStatusEnum.NOT_STARTED
                ? tr('FRONTEND.ONBOARDING.RESUME_ALERT_GET_STARTED', 'Get Started')
                : tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')
            }
            handleClick={startOrContinueOnboarding}
          />
        )
      }

      if (
        onboardingStatus === OnboardingStatusEnum.UNDER_REVIEW ||
        onboardingStatus === OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL ||
        onboardingStatus === OnboardingStatusEnum.WAITING_OPS_APPROVAL
      ) {
        return (
          <Banner
            button={button}
            className={className}
            content={tr('FRONTEND.ONBOARDING.RESUME_ALERT_UNDER_REVIEW', 'Your account verification is under review.')}
          />
        )
      }

      if (onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL) {
        return (
          <Banner
            button={button}
            className={className}
            content={tr(
              'FRONTEND.ONBOARDING.RESUME_ALERT_REJECTED',
              'Please contact Customer Service to get support on your account verification.'
            )}
          />
        )
      }
    }
  }

  return null
}
