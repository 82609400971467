import backButtonIcon from './images/icons/back-button.svg'
import backButtonIconHover from './images/icons/back-button-hover.svg'

import checkedIcon from './images/icons/checkbox-checked.svg'
import checkedAlternativeIcon from './images/icons/checkbox-alternative.svg'

import radioSelected from './images/icons/radio-selected.svg'
import radioUnselected from './images/icons/radio-unselected.svg'

import NextIcon from './images/icons/pagination-right.svg?react'
import PrevIcon from './images/icons/pagination-left.svg?react'

const theme = {
  fontName: 'Helvetica Now Display',

  containerBorderRadius: '1rem',
  resultQrCodeMargin: '0 1rem',

  header: {
    height: '7rem',
    heightThin: '6rem'
  },

  toast: {
    error: {
      iconTheme: { primary: 'red', secondary: 'white' },
      style: {
        color: '#ffffff',
        fontSize: '1.6rem',
        lineHeight: '120%',
        background: '#141414',
        borderRadius: '4rem'
      }
    },
    success: {
      iconTheme: { primary: 'green', secondary: 'white' },
      style: {
        color: '#ffffff',
        fontSize: '1.6rem',
        lineHeight: '120%',
        background: '#141414',
        borderRadius: '4rem'
      }
    }
  },

  arrowButton: {
    width: '40px',
    height: '40px',
    textColor: '#bdbdbd',
    hoverColor: '#ffffff',
    secondaryHoverColor: '#ffffff'
  },

  button: {
    loadingType: 'text',
    primary: {
      height: '4.6rem',
      background: '#000000',
      border: 'none',
      borderRadius: '4px',
      boxShadow: 'none',
      color: '#ffffff',
      fontSize: '1.4rem',
      fontWeight: '500',
      iconColor: '#ffffff',
      iconSpacing: '1.4rem',
      padding: '0 2rem',

      hoverBackground: 'rgba(0, 0, 0, 0.9)',
      hoverBoxShadow: 'none',
      hoverColor: '#ffffff',

      disabledColor: 'rgba(255, 255, 255, 0.25)',
      disabledBackground: '#1a1a1a',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    secondary: {
      height: '4.6rem',
      background: '#ffffff',
      border: '1px solid transparent',
      borderRadius: '4px',
      boxShadow: 'none',
      color: '#000000',
      fontSize: '1.4rem',
      fontWeight: '500',
      iconColor: '#000000',
      iconSpacing: '1.4rem',
      padding: '0 2rem',

      hoverBackground: '#dedede',
      hoverBoxShadow: 'none',
      hoverColor: '#000000',

      disabledColor: 'rgba(0, 0, 0, 0.25)',
      disabledBackground: '#ffffff',
      disabledBorder: '1px solid transparent',
      disabledBoxShadow: 'none'
    },
    alternative: {
      height: '4.6rem',
      background: '#e8e8e8',
      border: '1px solid transparent',
      borderRadius: '4px',
      boxShadow: 'none',
      color: '#000000',
      fontSize: '1.4rem',
      fontWeight: '500',
      iconColor: '#000000',
      iconSpacing: '1.4rem',
      padding: '0 2rem',

      hoverBackground: 'rgba(255, 255, 255, 0.8)',
      hoverBoxShadow: 'none',
      hoverColor: '#000000',

      disabledColor: 'rgba(0, 0, 0, 0.25)',
      disabledBackground: '#ffffff',
      disabledBorder: '1px solid transparent',
      disabledBoxShadow: 'none'
    },
    'round-primary': {
      height: '4rem',
      background: '#ffffff',
      border: 'none',
      borderRadius: '4.3rem',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: '#333333',
      fontSize: '1.4rem',
      fontWeight: '500',
      iconColor: '#333333',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2rem',

      hoverBackground: '#dfdce3',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: '#000000',

      disabledColor: '#828282',
      disabledBackground: '#333333',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    'round-secondary': {
      height: '4rem',
      background: '#1a1a1a',
      border: '1px solid #808080',
      borderRadius: '4.3rem',
      boxShadow: 'none',
      color: '#808080',
      fontSize: '1.4rem',
      fontWeight: '500',
      iconColor: '#808080',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2rem',

      hoverBackground: '#1a1a1a',
      hoverBorder: '1px solid #808080',
      hoverBoxShadow: 'none',
      hoverColor: '#ffffff',

      disabledColor: '#828282',
      disabledBackground: '#333333',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    'round-alternative': {
      height: '4rem',
      background: '#4654C9',
      border: 'none',
      borderRadius: '4.3rem',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: '#FFF',
      fontSize: '1.4rem',
      fontWeight: '500',
      iconColor: '#FFF',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2rem',

      hoverBackground: '#2C389D',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',

      disabledColor: '#828282',
      disabledBackground: '#333333',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    delete: {
      height: '4rem',
      background: '#ff0431',
      border: 'none',
      borderRadius: '4.3rem',
      boxShadow: 'none',
      color: '#ffffff',
      fontSize: '1.4rem',
      fontWeight: '500',
      iconColor: '#ffffff',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2rem',

      hoverBackground: '#ff0431',
      hoverBorder: 'none',
      hoverBoxShadow: 'none',
      hoverColor: '#ffffff',

      disabledColor: '#828282',
      disabledBackground: '#333333',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    }
  },

  checkbox: {
    checkedIconBackgroundColor: '#ffffff',
    checkedIconBorderColor: '#ffffff',
    checkedIconBackgroundColorAlternative: '#000000',
    checkedIconBorderColorAlternative: '#000000',
    iconBackground: 'transparent',
    iconBorder: '1px solid #4f4f4f',
    iconBorderRadius: '2px',
    iconSize: '13px',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: '500',
    textColor: '#828282'
  },

  contentWrapper: {
    backgroundColor: '#202020',
    boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
    textColor: '#f7f7f7',
    staticColor: '#939ea7',
    gridRowGap: '0',
    gridRowGap2: '0',
    gridRowGap3: '0'
  },

  datepicker: {
    container: {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: 'none',
      fontSize: '1.4rem',
      padding: '0 0 1rem'
    },
    dayName: {
      fontSize: '1.2rem',
      fontWeight: '700',
      lineHeight: '3rem',
      textColor: '#000000',
      marginTop: '2rem',
      marginBottom: '-8px'
    },
    header: {
      arrowSize: '2px',
      arrowTop: '1.5rem',
      arrowLeftRight: '1rem',
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      fontSize: '1.4rem',
      fontWeight: '500',
      lineHeight: '2rem',
      textColor: '#000000',
      padding: '2rem 2rem 0'
    },
    cells: {
      border: '1px solid transparent',
      borderRadius: '50%',
      height: '3rem',
      width: '3rem',
      fontSize: '1.2rem',
      fontWeight: '400',
      textColor: '#000000',
      outsideColor: '#afafaf',
      margin: '2px 4px',
      hoverBackground: 'transparent',
      hoverBorder: '1px solid #dcdfe3',
      hoverTextColor: '#000000',
      selectedBackground: '#000000',
      selectedBorder: '1px solid #000000',
      selectedColor: '#ffffff'
    }
  },

  dataTable: {
    body: {
      backgroundColor: 'transparent',
      borderColor: '#828282',
      textColor: '#ffffff',
      fontSize: '1.4rem',
      fontWeight: 'normal',
      dropShadow: 'none',
      infoLineColor: '#939ea7'
    },
    head: {
      background: 'transparent',
      border: 'none',
      textColor: '#828282',
      fontSize: '1.4rem',
      fontWeight: '500'
    },
    headCell: {
      padding: '2rem 0'
    },
    hover: {
      backgroundColor: '#1a1a1a',
      border: '1px solid transparent',
      textColor: '#ffffff'
    }
  },

  dropTarget: {
    background: 'rgba(0, 0, 0, 0.8)',
    border: '2px solid #828282',
    borderRadius: '2rem',
    textColor: '#ffffff',
    fontSize: '1.6rem',
    fontWeight: 'normal'
  },

  finalMessage: {
    fontSize: '1.6rem',
    fontWeight: '500',
    textColor: '#f7f7f7',
    infoTextColor: '#2f80ed',
    dangerTextColor: '#ee2b2b',
    successTextColor: '#59b300',
    padding: '0 2.5rem 4.5rem',
    contentPadding: '2.5rem 0 0'
  },

  gridTable: {
    border: '1px solid #828282',
    fontSize: '1.4rem',
    fontSize2: '1.6rem',
    fontWeight: '500',
    lineHeight: '1.7rem',
    lineHeight2: '2rem',
    labelColor: '#828282',
    valueColor: '#dfdce3',
    negativeColor: '#ee2b2b',
    positiveColor: '#59b300',
    rowPadding: '1rem 0',
    totalFontSize: '2rem',
    totalFontSize2: '2.4rem',
    totalFontWeight: 'bold',
    totalLineHeight: '2.4rem',
    totalLineHeight2: '3rem'
  },

  input: {
    label: {
      textColor: '#afafaf',
      fontSize: '1.2rem',
      fontWeight: '400',
      textTransform: 'none',
      marginBottom: '5px',
      marginLeft: '2rem',
      asteriskColor: '#afafaf',
      asteriskMargin: '0',
      asteriskPosition: 'right',
      paddingWithIcon: '0',
      paddingRequired: '0'
    },
    error: {
      textColor: '#ff0431',
      padding: '0 2.4rem',
      marginTop: '5px'
    },
    wrapper: {
      marginBottom: '3rem'
    },
    container: {
      textColor: '#ffffff',
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '140%',
      background: 'transparent',
      border: '1px solid #808080',
      borderWidth: '1px',
      borderRadius: '2.5rem',
      padding: '0 2rem',
      iconColor: '#ffffff',
      errorBorderColor: '#ff0431',
      errorBorderWidth: '1px',
      focusedBorderColor: '#808080',
      focusedBorderWidth: '1px'
    },
    placeholder: {
      textColor: '#808080',
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '140%'
    },
    dropDown: {
      controlBorder: '1px solid #808080',
      errorBorder: '1px solid #ff0431',
      controlBorderRadius: '4.2rem',
      controlPadding: '0 1rem 0 2rem',
      indicatorColor: '#808080',
      menuBackground: '#ffffff',
      menuBorder: 'none',
      menuBorderRadius: '0 0 2.5rem 2.5rem',
      menuMargin: '0',
      menuPadding: '1rem',
      openedBackground: '#ffffff',
      openedBorder: 'none',
      openedBorderRadius: '2.5rem 2.5rem 0 0',
      openedColor: '#000000',
      optionBorderRadius: '1rem',
      optionColor: '#000000',
      optionFontSize: '1.4rem',
      optionFontWeight: '400',
      optionLineHeight: '140%',
      optionMargin: '2px 0 0',
      optionPadding: '1rem',
      optionBackground: 'transparent',
      focusedOptionColor: '#ffffff',
      focusedOptionBackground: '#1a1a1a',
      buttonColor: '#ffffff',
      buttonTextAlign: 'center',
      buttonBorderRadius: '2rem',
      buttonBackground: '#1a1a1a',
      buttonMargin: '0 auto',
      buttonWidth: '90%'
    },
    settings: {
      height: '4rem',
      singleSelect: true,
      autofillBackground: '#404040',
      dropDownArrowColor: '#ffffff',
      textareaMargin: '1.3rem 0 1.5rem',
      textareaPadding: '0',
      hideLabelWhenEmpty: false,
      showPasswordWithText: false
    }
  },

  loader: {
    spinnerColor: '#828282',
    spinnerSize: 55,
    spinnerType: 'TailSpin',
    loaderColor: '#828282',
    loaderSize: 75
  },

  noElementsMessage: {
    background: 'transparent',
    border: 'none',
    borderRadius: '0',
    boxShadow: 'none',
    minHeight: '15rem',
    padding: '2rem',
    textColor: '#bdbdbd',
    fontSize: '2.4rem',
    fontWeight: '600',
    lineHeight: '3.6rem'
  },

  progressBar: {
    background: 'rgba(79, 79, 79, 1)',
    colorGreen: 'rgba(0, 180, 81, 1)',
    colorRed: 'rgba(232, 17, 45, 1)'
  },

  pagination: {
    marginPages: 1,
    minPagesVisible: 2,
    noArrowButtons: false,
    showPageLabel: false,
    fontSize: '1.4rem',
    fontWeight: '600',
    lineHeight: '1.7rem',
    textColor: '#ffffff',
    backgroundColor: '#202020',
    buttonBorderColor: 'transparent',
    buttonRadius: '0',
    buttonMargin: '0',
    buttonSize: '4rem',
    buttonTextColor: '#4f4f4f',
    buttonHoverBorderColor: 'transparent',
    buttonHoverTextColor: '#ffffff',
    buttonActiveBackground: '#202020',
    buttonActiveBorderRadius: '0',
    buttonActiveBoxShadow: 'none',
    buttonActiveTextColor: '#ffffff'
  },

  radioButton: {
    iconSize: '2rem',
    iconSpacing: '1rem',
    fontSize: '1.4rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    textColor: '#ffffff',
    loadingColor: '#828282',
    loadingSize: '30'
  },

  switchInput: {
    width: '70px',
    height: '40px',
    border: '1px solid #ffffff',
    borderRadius: '20px',

    color: '#ffffff',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '120%',

    ballSize: '34px',
    ballBackground: '#ffffff',
    ballBoxShadow: 'none',

    checkedBackground: 'transparent',
    uncheckedBackground: 'transparent',

    uncheckedBallColor: '#4f4f4f',
    uncheckedBorder: '1px solid #4f4f4f',

    disabledColor: '#4f4f4f',
    disabledBallColor: '#4f4f4f',
    disabledBorder: '#4f4f4f',
    disabledBackground: 'transparent',
    disabledOpacity: 'none'
  },

  backButtonIcon,
  backButtonIconHover,
  backButtonIconSecondary: backButtonIcon,
  backButtonIconSecondaryHover: backButtonIconHover,

  checkedIcon,
  checkedAlternativeIcon,
  radioSelected,
  radioUnselected,

  paginationNextIcon: NextIcon,
  paginationPrevIcon: PrevIcon
}

export default theme
