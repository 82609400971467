import styled from 'styled-components'

export const RadioButtonWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  cursor: pointer;

  display: grid;
  grid-template-columns: 25px 1fr;
  grid-gap: 2rem;
  border: 1px solid transparent;
  border-radius: 7px;

  &.selected {
    border-color: #4f4f4f;
    backdrop-filter: blur(44px);
  }

  &.disabled {
    cursor: not-allowed;
    color: #555;
  }

  .check-icon {
    display: flex;
    align-items: center;
  }

  .content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    color: #808080;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;

    .label {
      color: white;
      font-size: 2.2rem;
      font-style: normal;
      font-weight: normal;
      line-height: 120%;
      margin-bottom: 0.3rem;
    }

    .value {
      overflow-wrap: anywhere;
      word-break: break-all;
    }
  }

  &.disabled {
    .check-icon {
      img {
        display: none;
      }
    }
    .content {
      color: #555;
      .label {
        color: #555;
      }
    }
  }
`
