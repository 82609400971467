// noinspection CssUnusedSymbol,DuplicatedCode

import { useState, useEffect, Suspense } from 'react'
import { Switch } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'
import { Toaster } from 'react-hot-toast'
import { Preloader } from 'mmfintech-portal-commons'
import { ErrorBoundary } from 'react-error-boundary'
import { withTranslation } from 'react-i18next'
import { CookiesProvider, useCookies } from 'react-cookie'

import { ContentWrapper, CookieConsent, Header, ModalDialog, Otp, PrivateRoute, PublicRoute } from './components'

import theme from './theme'
import settings from './settings'
import { pathByStatus } from './utility'
import { GlobalContextProvider, isMobileDevice, isValidObject, OtpContext } from 'mmfintech-commons'
import { approverRoutes, publicRoutes, logoutAnd404Routes, routesMain, submitterRoutes } from './routes'
import {
  selectCurrentUserRole,
  selectCurrentUserStatus,
  useAppInitializerQry,
  useAppSelector,
  useAuthQry,
  useLazyErrorLoggingQuery
} from 'mmfintech-backend-api'

import { CustomerRoleEnum } from 'mmfintech-commons-types'

import ErrorIcon from './images/icons/error.svg?react'

const AppInner = () => {
  useAppInitializerQry(settings.languages)

  const { isAuthFetching } = useAuthQry()

  const [cookies, setCookie] = useCookies(['cookie.consent'])
  const [cookieConsent, setCookieConsent] = useState(null)

  const userStatus = useAppSelector(selectCurrentUserStatus)
  const customerRole = useAppSelector(selectCurrentUserRole)
  const queryChallenge = useAppSelector(state => state.challenge)

  const routes = () => {
    switch (customerRole) {
      case CustomerRoleEnum.SUBMITTER:
        return submitterRoutes
      case CustomerRoleEnum.APPROVER:
        return approverRoutes
      default:
        return routesMain
    }
  }

  useEffect(() => {
    setCookieConsent(cookies['cookie.consent'])
  }, [cookies])

  useEffect(() => {
    if (typeof queryChallenge === 'object' && queryChallenge.hasOwnProperty('challengeId')) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <Otp />
      })
    } else {
      modalHide()
    }
    // eslint-disable-next-line
  }, [queryChallenge])

  const [modalContent, setModalContent] = useState(null)
  const [modalOptions, setModalOptions] = useState(null)
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const [otpOnError, setOtpOnError] = useState(null)
  const [otpOnSuccess, setOtpOnSuccess] = useState(null)

  const modalHide = () => {
    setModalVisible(false)
    setModalContent(null)
    setModalOptions(null)
  }

  const modalShow = ({ options, content }) => {
    setModalContent(content)
    setModalOptions(options)
    setModalVisible(true)
  }

  const globalContext = { modalHide, modalShow }

  return isAuthFetching ? (
    <Preloader />
  ) : (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider context={globalContext}>
          <OtpContext.Provider value={{ otpOnSuccess, setOtpOnSuccess, otpOnError, setOtpOnError }}>
            <ContentWrapper>
              <Header />

              <Switch>
                {Array.isArray(publicRoutes) &&
                  publicRoutes.map(({ path, component }, index: number) => (
                    <PublicRoute key={index} path={path} exact component={component} />
                  ))}

                {Array.isArray(routes()) &&
                  routes().map(({ path, component, redirect }, index: number) => (
                    <PrivateRoute
                      key={index}
                      path={path}
                      exact
                      component={component}
                      redirect={redirect}
                      invalidSessionRedirect={pathByStatus(userStatus)}
                    />
                  ))}

                {Array.isArray(logoutAnd404Routes) &&
                  logoutAnd404Routes.map(({ path, component }, index: number) => (
                    <PublicRoute key={index} path={path} exact component={component} />
                  ))}
              </Switch>

              {isValidObject(cookieConsent) || isMobileDevice() ? null : <CookieConsent setCookie={setCookie} />}

              <ModalDialog content={modalContent} options={modalOptions} visible={modalVisible} onClose={modalHide} />
            </ContentWrapper>

            <Toaster
              position='top-right'
              containerStyle={{
                top: '10rem',
                zIndex: '10010'
              }}
              toastOptions={{
                className: '',
                style: {
                  color: '#000000',
                  fontFamily: 'inherit',
                  fontSize: '1.4rem',
                  fontStyle: 'normal',
                  padding: '1.5rem',
                  borderRadius: '0'
                },
                success: {}
              }}
            />
          </OtpContext.Provider>
        </GlobalContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <AlertWrapper>
      <Alert>
        <ErrorIcon />
        <p>An unexpected error occurred</p>
        <TryAgainButton type='button' onClick={resetErrorBoundary}>
          Go back to the home page
        </TryAgainButton>
      </Alert>
    </AlertWrapper>
  )
}

const ThisApp = withTranslation()(AppInner)

const App = () => {
  const [logError] = useLazyErrorLoggingQuery()

  return (
    <Suspense fallback={<Preloader />}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err, componentStack) => {
          return logError({ level: 'ERROR', componentStack, message: err.toString() })
        }}
        onReset={() => {
          window.location.replace('/')
        }}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App

const TryAgainButton = styled.button`
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 1.5rem;

  color: #ffffff;
  background: #ff4c4d;
  box-shadow: 0 5px 17px rgba(255, 165, 159, 0.5);

  border-radius: 5px;
  border: none;
`

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8rem;
`
const Alert = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  max-width: 50rem;
  width: 100%;
  border-radius: 1rem;
  padding: 3rem;

  text-align: center;
  font-size: 16px;

  img {
    width: 66px;
    height: 38px;
  }
  .button {
    width: 100%;
    max-width: 30rem;
  }
`
